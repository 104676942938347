import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Banner from '../components/banner'
import Laboratories from '../components/laboratories'
import SEO from '../components/seo'

const LaboratoriesTemplate = ({ data, location }) => {
  const {
    wpPage: page,
    allWpLaboratory: { nodes: laboratories },
  } = data
  const banner = page.template.componentBanner

  const filteredLaboratories = laboratories.filter((lab) => {
    if (lab.id !== 'cG9zdDoxMDk2') {
      return lab
    }
  })

  return (
    <Layout location={location}>
      <SEO
        title={page.title}
        metaTitle={page.seo.title}
        description={page.seo.metaDesc}
      />
      <Banner
        image={banner.bannerImage}
        excerpt={banner.excerpt}
        title={page.title}
        breadcrumbs={page.seo.breadcrumbs}
      />
      <Laboratories list={filteredLaboratories} />
    </Layout>
  )
}

export default LaboratoriesTemplate

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      template {
        ... on WpLaboratoriesTemplate {
          componentBanner {
            excerpt
            bannerImage {
              title
              publicUrl
              sourceUrl
            }
          }
        }
      }
      seo {
        breadcrumbs {
          text
          url
        }
        metaDesc
        title
      }
    }
    allWpLaboratory {
      nodes {
        uri
        title
        id
        templateLaboratory {
          city
        }
      }
    }
  }
`
