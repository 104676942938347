import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const LaboratoryCard = ({ title, city, path }) => {
  return (
    <Link
      to={path}
      className={'bg-blue-lighter rounded-lg pt-3 pb-3.5 px-4 flex flex-col'}
    >
      <p className={'text-xl text-blue-main font-semibold'}> {title}</p>
      <p className={'text-lg text-blue-main'}> {city}</p>
    </Link>
  )
}

export default LaboratoryCard

LaboratoryCard.propTypes = {
  title: PropTypes.string,
  city: PropTypes.string,
}
