import React from 'react'
import PropTypes from 'prop-types'
import LaboratoryCard from './card'

const Laboratories = ({ list }) => {
  const sorted = list.sort(function (a, b) {
    return a.title.localeCompare(b.title, 'nb-NO')
  })
  return (
    <>
      {list && (
        <div
          className={
            'my-6 md:mt-8 md:mb-10 lg:mt-20 lg:mb-32 xl-container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6 xl:px-36'
          }
        >
          {sorted.map((item, index) => (
            <LaboratoryCard
              key={index}
              title={item.title}
              city={item.templateLaboratory.city}
              path={item.uri}
            />
          ))}
        </div>
      )}
    </>
  )
}

export default Laboratories

Laboratories.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
}
